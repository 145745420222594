@tailwind base

.header {
}

.container {
}

.logo {
  width: 155px;
  cursor: pointer;

  > span {
    display: block !important;
  }
}

.action {
  @apply px-1;
  @apply ml-1;
  @apply hover:text-primary;
  @apply active:text-primary-dark;
  @apply transition-colors;
  @apply leading-10;

  &.user {

  }
}
